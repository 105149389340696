/** @jsx jsx */
import { Styled, jsx } from "theme-ui"

export default ({ children, title }) => (
  <Styled.root>
    <div
      sx={{
        maxWidth: "container",
        px: [3, 4, 5],
      }}
    >
      {children}
    </div>
  </Styled.root>
)
